/* eslint-disable react/no-array-index-key */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import Img from 'gatsby-image';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

const imgSize = {
  height: '200px',
  width: '200px',
};

/**
 * About page Team component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Team />
 */

const Team = () => {
  const {
    tm1,
    tm2,
    tm3,
    tm4,
    tm5,
    tm6,
    tm7,
    tm8,
    tm9,
    tm10,
  } = useStaticQuery(graphql`
    query {
      tm1: contentfulAsset(title: { eq: "about-team-1" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm2: contentfulAsset(title: { eq: "about-team-2" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm3: contentfulAsset(title: { eq: "about-team-3" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm4: contentfulAsset(title: { eq: "about-team-4" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm5: contentfulAsset(title: { eq: "about-team-5" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm6: contentfulAsset(title: { eq: "about-team-6" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm7: contentfulAsset(title: { eq: "about-team-7" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm8: contentfulAsset(title: { eq: "about-team-8" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm9: contentfulAsset(title: { eq: "about-team-9" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      tm10: contentfulAsset(title: { eq: "about-team-10" }) {
        fixed(height: 200, width: 200, quality: 80) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  `);

  // list of image objects for gatsby-image
  const topImgs = [
    tm1.fixed,
    tm2.fixed,
    tm3.fixed,
    tm4.fixed,
    tm5.fixed,
    tm7.fixed,
    tm8.fixed,
    tm9.fixed,
    tm4.fixed,
    tm6.fixed,
  ];
  const botImgs = [
    tm2.fixed,
    tm3.fixed,
    tm10.fixed,
    tm5.fixed,
    tm1.fixed,
    tm2.fixed,
    tm3.fixed,
    tm4.fixed,
    tm9.fixed,
    tm5.fixed,
  ];

  return (
    <section className={cx(base.overflowHidden, base.w100, main.mt120)}>
      <ul
        className={cx(
          base.flex,
          base.justifyEnd,
          base.ma0,
          base.pa0,
          base.w100,
          main.aboutTeamsImgList
        )}
      >
        {/* map over top images */}
        {topImgs.map((img, index) => (
          <li
            key={`${index}-${img.src}`}
            className={cx(base.overflowHidden, main.aboutTeamsImg)}
          >
            <Img
              fixed={img}
              alt=""
              aria-hidden="true"
              style={imgSize}
              imgStyle={imgSize}
            />
          </li>
        ))}
      </ul>
      <ul
        className={cx(
          base.flex,
          base.justifyStart,
          base.ma0,
          base.pa0,
          base.w100,
          main.aboutTeamsImgList
        )}
      >
        {/* map over bottom images */}
        {botImgs.map((img, index) => (
          <li key={`${index}-${img.src}`} className={main.aboutTeamsImg}>
            <Img
              fixed={img}
              alt=""
              aria-hidden="true"
              style={imgSize}
              imgStyle={imgSize}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Team;
