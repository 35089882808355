import React from 'react';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * About page Hero component.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => (
  <section
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyCenter,
      base.w100,
      main.heroAbout,
      main.px60
    )}
  >
    <h1 className={cx(main.heading, theme.colorDark)}>
      We are transforming how <br /> health plans
      <span className={theme.colorBlue}> engage</span> with members.
    </h1>
    <p
      className={cx(base.ma0, base.tc, base.w100, main.stopy, theme.colorDark)}
    >
      We are changing the fundamentals of patient and provider engagement. For
      years, health plans have relied on descriptive data and reactive
      engagement. We empower our clients to understand and predict the whole
      member journey, enabling sustained improvements in member health outcomes
      and plan performance. We combine the latest, most practical technologies
      and a deep understanding of healthcare, bringing innovative, pragmatic
      solutions to an industry that touches us all.
    </p>
  </section>
);

export default Hero;
