import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * About page Care component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Care />
 */

const Care = () => {
  const { beer, bulb, check, home, hospital, money } = useStaticQuery(graphql`
    query {
      beer: contentfulAsset(title: { eq: "about-beer" }) {
        file {
          url
        }
      }
      bulb: contentfulAsset(title: { eq: "about-bulb" }) {
        file {
          url
        }
      }
      check: contentfulAsset(title: { eq: "about-check" }) {
        file {
          url
        }
      }
      home: contentfulAsset(title: { eq: "about-property" }) {
        file {
          url
        }
      }
      hospital: contentfulAsset(title: { eq: "about-hospital" }) {
        file {
          url
        }
      }
      money: contentfulAsset(title: { eq: "about-moneybag" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.aboutCare,
        main.mb120,
        main.mt120,
        main.px60
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyStart,
          base.w100,
          main.aboutCareText
        )}
      >
        <h2 className={cx(base.mb5, main.subHeading)}>
          We <span className={theme.colorBlue}> care </span> about our teams.
        </h2>
        <p className={base.tl}>
          We’re as passionate about our people as we are about making our mark
          on healthcare. Fostering a fun and challenging environment that’s
          centered around personal and professional growth has brought us to
          where we are today. We are constantly seeking out new ways to reinvest
          in our team members because let’s face it, we all do our best work
          when we feel valued.
        </p>
      </div>
      <ul
        className={cx(
          base.flex,
          base.flexWrap,
          base.justifyStart,
          base.w100,
          main.aboutCareSection
        )}
      >
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={check.file.url.replace(/^/g, 'https:')}
            alt="Checkmark."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>Meaningful work.</p>
        </li>
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={home.file.url.replace(/^/g, 'https:')}
            alt="House with heart at center."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>
            Remote friendly environment.
          </p>
        </li>
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={bulb.file.url.replace(/^/g, 'https:')}
            alt="Lightbulb."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>
            We encourage outside the box ideas.
          </p>
        </li>
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={hospital.file.url.replace(/^/g, 'https:')}
            alt="Hospital symbol."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>
            Great healthcare coverage.
          </p>
        </li>
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={money.file.url.replace(/^/g, 'https:')}
            alt="Money bag."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>Competitive compensation.</p>
        </li>
        <li
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.w100,
            main.aboutCareItem
          )}
        >
          <img
            src={beer.file.url.replace(/^/g, 'https:')}
            alt="Beer mug."
            className={main.iconImg}
          />
          <p className={cx(base.ma0, main.stCopy)}>Social outings.</p>
        </li>
      </ul>
    </section>
  );
};

export default Care;
