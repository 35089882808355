import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

const imgSize = {
  maxWidth: '800px',
};

/**
 * About page Founders component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Founders />
 */

const Founders = () => {
  const { contentfulAsset } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "about-founders" }) {
        fluid(maxWidth: 900, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.px60
      )}
    >
      <Img
        fluid={contentfulAsset.fluid}
        alt="Founders Saeed Aminzadeh and Craig Johnson."
        style={imgSize}
        imgStyle={imgSize}
        className={cx(main.aboutFoundersImg, main.mb120)}
      />
      <h1 className={cx(base.tc, main.heading, theme.colorDark)}>
        Founded with <span className={theme.colorBlue}>people </span>
        in mind.
      </h1>
      <div className={cx(base.w100, main.aboutFoundersText, theme.colorDark)}>
        <p>
          We are both husbands, fathers, and entrepreneurs who set out to solve
          a meaningful problem. In late 2012, with the backdrop of dramatic
          trends sweeping the industry, we began discussing the new challenges
          facing health plans. We questioned why health plans rely on reactive
          member engagement and one-size-fits-all messaging, when consumer
          brands we interact with every day were delivering highly-targeted and
          personalized experiences. With past roles at organizations such as
          Kaiser Permanente, Optum, and NASA, we decided there had to be a
          better way. Decision Point was born.
        </p>
        <p>
          A person’s health plan holds the unique and vital responsibility to
          act as a beacon of hope, trust, and security. We started Decision
          Point to help health plans lift their performance while delivering an
          experience that will delight and engage their members. By putting the
          member first and taking steps to understand them far beyond their
          clinical profile, plans are able to anticipate their needs, which when
          coupled with meaningful, focused health plan engagements, results in
          happier, healthier people.
        </p>
        <p>
          Since 2012, we’ve come a long way but have held firm to our original
          philosophy. We strive to be the best partner we can be to our
          customers: to us, this means taking the extra steps to make sure that
          our customers are successful, informed and operating at peak levels.
        </p>
        <p>
          In late 2023, Decision Point was acquired by{' '}
          <a href="https://mpulse.com/">mPulse</a>, a leader in digital health
          engagement. We are thrilled to now call ourselves a full-service
          healthcare engagement company; gaining the resources and capabilities
          to provide our customers with everything they need to maximize the
          patient experience.
        </p>
        <p>
          We will continue to develop new products and solutions that stay ahead
          of industry change and evolving patient needs, in order to best serve
          our clients and those that count on them.
        </p>
      </div>
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyAround,
          base.w100,
          main.aboutFounderNames,
          main.mt30
        )}
      >
        <div className={cx(base.itemsCenter, base.tc, main.aboutFounderItem)}>
          <a
            href="https://www.linkedin.com/in/craigjohnson1000"
            target="_blank"
            rel="noopener noreferrer"
            className={cx(main.contentLink, main.nu, theme.title2)}
          >
            Craig Johnson
          </a>
          <div className={theme.title3}>Co-founder</div>
          <div className={theme.title3}>Chief Science Officer </div>
        </div>

        <div className={cx(base.itemsCenter, base.tc, main.aboutFounderItem)}>
          <a
            href="https://www.linkedin.com/in/saeed-aminzadeh-9b70294"
            target="_blank"
            rel="noopener noreferrer"
            className={cx(base.mb2, main.contentLink, main.nu, theme.title2)}
          >
            Saeed Aminzadeh
          </a>
          <div className={theme.title3}>Co-founder</div>
          <div className={theme.title3}>Chief Executive Officer </div>
        </div>
      </div>
    </section>
  );
};

export default Founders;
