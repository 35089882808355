import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';
// import SVG as Component; that is, plain HTML
import { ReactComponent as Arrow } from '../../images/arrow-right.svg';

/**
 * About page Jobs component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Jobs />
 */

const Jobs = () => {
  const { openings } = useStaticQuery(graphql`
    query {
      openings: allContentfulJobOpenings {
        edges {
          node {
            slug
            title
            where
          }
        }
      }
    }
  `);

  return (
    <section
      id="jobs"
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.px60
      )}
    >
      <div className={cx(base.w100, main.aboutJobsWrapper)}>
        <h2 className={cx(base.mb2, theme.colorDark, theme.title1)}>
          Come work with us.
        </h2>
        <p className={cx(base.mb0, main.stCopy, theme.colorDark)}>
          Looking for a career where you can do meaningful work, take full
          ownership of your role, and learn every day? Check out our openings
          below and come join a team of passionate movers, shakers, and
          innovators.
        </p>
        <ul
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.mb0,
            base.mt3,
            base.pa0,
            base.pt2,
            base.w100
          )}
        >
          {/* map over current job openings; sourced from Contentful */}
          {openings.edges.map(({ node }) => {
            const { slug, title, where } = node;
            return (
              <li key={slug} className={cx(base.w100, main.ln)}>
                <Link
                  to="https://mpulseportal.rec.pro.ukg.net/MPU1000PULSE/JobBoard/dc48557c-996a-4e30-a53d-fd3dfb186808/?q=&o=postedDateDesc"
                  className={cx(
                    base.flex,
                    base.itemsCenter,
                    base.justifyBetween,
                    base.w100,
                    main.aboutJobPost
                  )}
                >
                  <p
                    className={cx(
                      base.ma0,
                      base.mr2,
                      main.stCopy,
                      theme.colorBlue
                    )}
                  >
                    {title}
                  </p>
                  <div
                    className={cx(
                      base.flex,
                      base.itemsCenter,
                      base.justifyBetween,
                      base.w100,
                      main.aboutJobLink
                    )}
                  >
                    <p
                      className={cx(
                        base.ma0,
                        main.aboutJobLocation,
                        main.stCopy,
                        theme.colorDark
                      )}
                    >
                      {where}
                    </p>
                    <Arrow aria-hidden="true" className={main.aboutJobArrow} />
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Jobs;
