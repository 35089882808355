import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * About page Icons component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Icons />
 */

const Icons = () => {
  const { brain, group, park } = useStaticQuery(graphql`
    query {
      brain: contentfulAsset(title: { eq: "about-artificial-brain" }) {
        file {
          url
        }
      }
      group: contentfulAsset(title: { eq: "about-group" }) {
        file {
          url
        }
      }
      park: contentfulAsset(title: { eq: "about-park" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mb120,
        main.mt30
      )}
    >
      <ul
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsStart,
          base.justifyCenter,
          base.pl0
        )}
      >
        <li
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsCenter,
            base.justifyBetween,
            main.aboutHeroItem
          )}
        >
          <img
            src={brain.file.url}
            alt="Aftificial brain."
            className={main.aboutHeroItemIcon}
          />
          <p
            className={cx(
              base.mb0,
              base.tc,
              main.aboutHeroItemCopy,
              theme.colorTextGrey,
              theme.title3
            )}
          >
            We are passionate data and technology experts.
          </p>
        </li>
        <li
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsCenter,
            base.justifyBetween,
            main.aboutHeroItem
          )}
        >
          <img
            src={park.file.url}
            alt="Park bench and tree."
            className={main.aboutHeroItemIcon}
          />
          <p
            className={cx(
              base.mb0,
              base.tc,
              main.aboutHeroItemCopy,
              theme.colorTextGrey,
              theme.title3
            )}
          >
            We are determined to leave a lasting impact on healthcare.
          </p>
        </li>
        <li
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsCenter,
            base.justifyBetween,
            main.aboutHeroItem
          )}
        >
          <img
            src={group.file.url}
            alt="Heirarchal group."
            className={main.aboutHeroItemIcon}
          />
          <p
            className={cx(
              base.mb0,
              base.tc,
              main.aboutHeroItemCopy,
              theme.colorTextGrey,
              theme.title3
            )}
          >
            We love building scalable products.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Icons;
