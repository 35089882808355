import React from 'react';
import PropTypes from 'prop-types';

import Boston from '../components/about/aboutBoston';
import Care from '../components/about/aboutCare';
import Founders from '../components/about/aboutFounders';
import Hero from '../components/about/aboutHero';
import Icons from '../components/about/aboutIcons';
import Jobs from '../components/about/aboutJobs';
import Layout from '../components/layout';
import Team from '../components/about/aboutTeam';

const pageDescription =
  'We empower our clients to understand and predict the whole member journey, enabling sustained improvements in member health outcomes and plan performance.';

/**
 * About page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <About location={string} />
 */

const About = ({ location }) => (
  <Layout
    pageTitle="About Us"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <Icons />
    <Founders />
    <Team />
    <Care />
    <Boston />
    <Jobs />
  </Layout>
);

About.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default About;
