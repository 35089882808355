import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * About page Boston component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Boston />
 */

const Boston = () => {
  const { contentfulAsset } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "about-boston" }) {
        fluid(quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.overflowHidden,
        base.relative,
        base.w100,
        main.aboutBoston
      )}
    >
      <div className={cx(main.aboutBostonTag, theme.shapeWhite)}>
        <h2 className={cx(base.mb2, base.tc, main.subHeading)}>
          Built in<span className={theme.colorBlue}> Boston</span>,
          <br /> the heart of HealthTech.
        </h2>
        <p className={cx(base.tc, base.mb0)}>
          We’re proud to be born and bred in the hub of healthcare technology.
          Located downtown, Decision Point is in close proximity to some of the
          world’s leading hospitals, universities, pharma and health-tech
          startups. We were founded within the central pulse of healthcare
          innovation and we feel it every day.
        </p>
      </div>
      <div
        className={cx(
          base.absolute,
          base.bottom0,
          base.left0,
          base.right0,
          base.top0,
          base.w100,
          main.aboutBostonImgWrapper
        )}
      >
        <Img
          fluid={contentfulAsset.fluid}
          alt=""
          aria-hidden="true"
          className={base.w100}
        />
        <div
          className={cx(
            base.absolute,
            base.h100,
            base.left0,
            base.top0,
            base.w100,
            main.aboutBostonImgOverlay
          )}
        />
      </div>
    </section>
  );
};

export default Boston;
